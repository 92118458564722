<!-- eslint-disable -->
<template>
  <v-container v-once>
    <v-row>
      <v-col cols="12">
        <DIV id="page_1">
          <P class="p0 ft0">GENERAL TERMS AND CONDITIONS VISIONBOOK</P>
          <P class="p2 ft3"
            >By means of
            <SPAN class="ft1">Software-as-a-Service</SPAN>
            <SPAN class="ft1"> </SPAN>(<SPAN class="ft2">SaaS</SPAN>), we, The
            Scalable Factory B.V., offer you the following Software "Visionbook"
            (the <SPAN class="ft2">Software</SPAN>). This means that we offer
            you access to the Software we have developed, via the internet.
            These are the general terms and conditions that are always
            applicable to the use of our Software.</P
          >
          <P class="p3 ft4"
            >If you have any questions regarding these terms and conditions or
            the Software, you can contact us by sending an email to
            info@visionbook.com.</P
          >
          <P class="p4 ft6"
            >Our address is Willem van Noortstraat 76, 3514 GG Utrecht, The
            Netherlands. We are registered with the Chamber of Commerce (<SPAN
              class="ft5"
              >Kamer van Koophandel</SPAN
            >) under number: 78141788.</P
          >
          <P class="p5 ft7"
            >We have the right to change these general terms and conditions at
            all times. The latest version of these terms and conditions will
            always apply. Arrangements that deviate from these terms and
            conditions will only be applicable if they have been agreed on by us
            in writing.</P
          >
          <P class="p6 ft10"
            ><SPAN class="ft8">1.</SPAN><SPAN class="ft9">General</SPAN></P
          >
          <P class="p9 ft7"
            ><SPAN class="ft8">1.1.</SPAN
            ><SPAN class="ft15"
              >These terms and conditions apply to every offer and agreement
              between you and us.</SPAN
            ></P
          >
          <P class="p9 ft7"
            ><SPAN class="ft8">1.2.</SPAN
            ><SPAN class="ft15"
              >We shall send you these general terms and conditions at your
              request, free of charge. You can also find them on our website
              www.visionbook.com.</SPAN
            ></P
          >
          <P class="p9 ft7"
            ><SPAN class="ft8">1.3.</SPAN
            ><SPAN class="ft15"
              >The invalidity or unenforceability of any provision of this
              agreement shall not affect the validity or enforceability of any
              other provision of this agreement. Any such invalid or
              unenforceable provision shall be replaced by a provision that is
              considered to be valid and enforceable and whose interpretation
              shall be as close as possible to the intent of the invalid
              provision.</SPAN
            ></P
          >
          <P class="p10 ft10"
            ><SPAN class="ft16">2.</SPAN
            ><SPAN class="ft9">Proposals and offers</SPAN></P
          >
          <P class="p11 ft14"
            ><SPAN class="ft8">2.1.</SPAN
            ><SPAN class="ft17">All our proposals and offers are </SPAN
            >non-binding, unless expressly agreed otherwise. An offer or
            proposal only applies to the assignment specified therein (and not
            to possible future assignments).</P
          >
          <P class="p12 ft19"
            ><SPAN class="ft16">2.2.</SPAN
            ><SPAN class="ft18"
              >When you provide us with certain information, we may assume that
              the provided information is correct and we will base the proposal
              on that information.</SPAN
            ></P
          >
          <P class="p13 ft10"
            ><SPAN class="ft16">3.</SPAN><SPAN class="ft9">Costs</SPAN></P
          >
          <P class="p15 ft14"
            ><SPAN class="ft16">3.1.</SPAN
            ><SPAN class="ft17"
              >Offered prices include expenses and taxes.</SPAN
            ></P
          >
          <P class="p15 ft14"
            ><SPAN class="ft16">3.2.</SPAN
            ><SPAN class="ft17"
              >We have the right to adjust our prices at any given time. The new
              price will then be applicable thirty days after its
              announcement.</SPAN
            ></P
          >
          <P class="p16 ft14"
            ><SPAN class="ft16">3.3.</SPAN
            ><SPAN class="ft17"
              >In case you do not agree with the change of price in question,
              you have the right to cancel the agreement within thirty days days
              after the announcement. The agreement will then terminate on the
              day the new prices become applicable.</SPAN
            ></P
          >
          <P class="p17 ft10"
            ><SPAN class="ft21">4.</SPAN
            ><SPAN class="ft9">Payment and Collection Charges</SPAN></P
          >
          <P class="p19 ft3"
            ><SPAN class="ft16">4.1.</SPAN
            ><SPAN class="ft22"
              >We shall automatically charge your bank account monthly or yearly
              based on the starting date.</SPAN
            ></P
          >
          <P class="p19 ft3"
            ><SPAN class="ft16">4.2.</SPAN
            ><SPAN class="ft22"
              >If a payment is due, and you have failed to meet this payment
              obligation, you will automatically be in default. A default notice
              is not required. In that case, you owe us the statutory
              (commercial) interest plus 1% over the amount due. The interest is
              calculated from the moment that the payment becomes due, until the
              moment that the amount has been paid in full.</SPAN
            ></P
          >
          <P class="p20 ft14"
            ><SPAN class="ft16">4.3.</SPAN
            ><SPAN class="ft13"
              >In case of default, you additionally owe us all extrajudicial
              costs. In case of an invoice up to €267 these costs will be €40.
              In case of a higher invoice amount, the maximum collection fees
              are as follows:</SPAN
            ></P
          >
          <P class="p21 ft12"
            ><Icon x-small iconClass="pb-2" color="black">{{
              svgMovieOpen
            }}</Icon
            ><SPAN class="ft20">15% over the first €2.500;</SPAN></P
          >
          <P class="p21 ft12"
            ><Icon x-small iconClass="pb-2" color="black">{{
              svgMovieOpen
            }}</Icon
            ><SPAN class="ft20"
              >10% over the part that remains thereafter, up to €5.000;</SPAN
            ></P
          >
          <P class="p22 ft12"
            ><Icon x-small iconClass="pb-2" color="black">{{
              svgMovieOpen
            }}</Icon
            ><SPAN class="ft20"
              >5% over the part that remains thereafter, up to €10.000;</SPAN
            ></P
          >
          <P class="p21 ft12"
            ><Icon x-small iconClass="pb-2" color="black">{{
              svgMovieOpen
            }}</Icon
            ><SPAN class="ft20"
              >1% over the part that remains thereafter, up to €200.000;</SPAN
            ></P
          >
          <P class="p22 ft12"
            ><Icon x-small iconClass="pb-1" color="black">{{
              svgMovieOpen
            }}</Icon
            ><SPAN class="ft20"
              >0,5% over the remaining part, whereby the total collection fees
              are maximised to €26.775.</SPAN
            ></P
          >
          <P class="p24 ft10"
            ><SPAN class="ft16">5.</SPAN
            ><SPAN class="ft9">Use of "Visionbook"</SPAN></P
          >
          <P class="p29 ft3"
            ><SPAN class="ft16">5.1.</SPAN
            ><SPAN class="ft22"
              >If you want to use our Software, you will have to register
              yourself at our website. You will subsequently have to await our
              approval while we process your registration.</SPAN
            ></P
          >
          <P class="p29 ft3"
            ><SPAN class="ft16">5.2.</SPAN
            ><SPAN class="ft22"
              >You can only create an account for yourself.</SPAN
            ></P
          >
          <P class="p29 ft3"
            ><SPAN class="ft16">5.3.</SPAN
            ><SPAN class="ft22"
              >You shall keep your account details and password confidential.
              You are responsible for all activities on your account after it
              has been logged in with the account details and passwords, unless
              you have reported as soon as becoming aware of it that your
              personal account has been compromised.</SPAN
            ></P
          >
          <P class="p28 ft3"
            ><SPAN class="ft16">5.4.</SPAN
            ><SPAN class="ft22"
              >In order to use our Software, you must have a proper internet
              connection. You are responsible for the technical functioning and
              maintenance of your internet connection, internal network and all
              other </SPAN
            >IT-systems if so required according to our system requirements.</P
          >
          <P class="p29 ft3"
            ><SPAN class="ft16">5.5.</SPAN
            ><SPAN class="ft22"
              >We have the right to block accounts. We only do this in case we
              have reasonable belief that one or more accounts are used in a
              matter that is against the law or contrary to a provision of these
              terms. Furthermore, we have the right to take any other measures
              we deem adequate, taking into account the circumstances at
              hand.</SPAN
            ></P
          >
          <P class="p30 ft10"
            ><SPAN class="ft16">6.</SPAN
            ><SPAN class="ft9"
              >Availability and maintenance of Visionbook</SPAN
            ></P
          >
          <P class="p33 ft7"
            ><SPAN class="ft16">6.1.</SPAN
            ><SPAN class="ft27"
              >We shall ensure that the Software will be kept available for use
              for the entire duration of this agreement. We shall do our best to
              keep the Software up and running 24 hours a day, 7 days a week. We
              are responsible for the availability and maintenance of the
              Software.</SPAN
            ></P
          >
          <P class="p33 ft7"
            ><SPAN class="ft16">6.2.</SPAN
            ><SPAN class="ft27"
              >During maintenance, the Software can be unavailable. Maintenance
              will, in principle, never take place during office hours (09:00 -
              17:00). We will notify you of such maintenance at least 5 working
              days in advance in case the maintenance could affect your use of
              the service. Only in case of emergencies, we will not send you a
              notification.</SPAN
            ></P
          >
          <P class="p34 ft19"
            ><SPAN class="ft16">6.3.</SPAN
            ><SPAN class="ft18"
              >We have the right to change the Software. This includes, but is
              not limited to, changing, removing or adding certain features or
              functionalities of the Software.</SPAN
            ></P
          >
          <P class="p35 ft3"
            ><SPAN class="ft16">6.4.</SPAN
            ><SPAN class="ft22"
              >We do not guarantee that our Software is completely free of
              error. Please inform us immediately of any errors, bugs or
              malfunctioning of the Software. You can reach out to us by sending
              an email to info@visionbook.com. We will then do our utmost to
              resolve your problem as soon as possible.</SPAN
            ></P
          >
          <P class="p30 ft10"
            ><SPAN class="ft16">7.</SPAN
            ><SPAN class="ft9">Third parties</SPAN></P
          >
          <P class="p36 ft7"
            >We have the right to employ third parties to partially perform our
            duties, if we are of the opinion that this is necessary for the due
            exercise of the SaaS Agreement. Articles 7:404 of the Dutch Civil
            Code (<SPAN class="ft28">uitvoering door bepaalde persoon</SPAN>),
            7:407 section 2 DCC (<SPAN class="ft28"
              >hoofdelijke aansprakelijkheid</SPAN
            >) and 7:409 DCC (<SPAN class="ft28"
              >overlijden van bepaalde persoon</SPAN
            >) are not applicable.</P
          >
          <P class="p30 ft10"
            ><SPAN class="ft21">8.</SPAN
            ><SPAN class="ft9">Force Majeure</SPAN></P
          >
          <P class="p37 ft3"
            >We are not liable for any damages in case of force majeure. If the
            force majeure takes place for a period that exceeds 1 month, this
            agreement can be terminated in writing. In that case, parties have
            no right to recover damages. We will then send you an invoice
            regarding the period in which you have used our Software.</P
          >
          <P class="p38 ft10"
            ><SPAN class="ft21">9.</SPAN
            ><SPAN class="ft29">Intellectual property</SPAN></P
          >
          <P class="p41 ft3"
            ><SPAN class="ft16">9.1.</SPAN
            ><SPAN class="ft22"
              >We (or our licensor or suppliers) are the exclusive owners of all
              existing and future intellectual property, such as copyrights,
              trademarks, design rights, patents, source codes and know-how,
              which rest on our Software or are the fruits of the use of our
              Software.</SPAN
            ></P
          >
          <P class="p41 ft3"
            ><SPAN class="ft16">9.2.</SPAN
            ><SPAN class="ft22"
              >As a user, you only gain the right to use our Software. You
              cannot claim any of the in subsection 1 mentioned intellectual
              property. This is not an exclusive right, which means that we can
              grant others similar rights of use. Furthermore, it is expressly
              forbidden to transfer or license this right to any third
              party.</SPAN
            ></P
          >
          <P class="p10 ft10"
            ><SPAN class="ft16">10.</SPAN
            ><SPAN class="ft9">Non-disclosure</SPAN></P
          >
          <P class="p42 ft3"
            >We are obliged not to disclose any of your confidential information
            to third parties unless it is required by a statutory or
            professional obligation. 'Confidential information' includes all
            information which you have designated as confidential or which by
            its nature can be classified as confidential.</P
          >
          <P class="p10 ft10"
            ><SPAN class="ft8">11.</SPAN><SPAN class="ft9">Liability</SPAN></P
          >
          <P class="p43 ft7"
            ><SPAN class="ft31">11.1.</SPAN
            ><SPAN class="ft27"
              >You indemnify us for all claims by third parties relating to the
              data that you have collected, saved or processed by means of our
              Software. We are not liable for the content of the data that you
              have collected, saved or processed within the framework of our
              Software.</SPAN
            ></P
          >
          <P class="p46 ft26"
            ><SPAN class="ft8">11.2.</SPAN
            ><SPAN class="ft20"
              >We are not liable for any damage which is caused by inadequate
              use of our Software.</SPAN
            ></P
          >
          <P class="p46 ft26"
            ><SPAN class="ft8">11.3.</SPAN
            ><SPAN class="ft32"
              >We are not liable for direct damages that are unequivocally
              caused by a shortcoming from our side.</SPAN
            ></P
          >
          <P class="p46 ft26"
            ><SPAN class="ft8">11.4.</SPAN
            ><SPAN class="ft33"
              >We undertake the responsibility to ensure that your data will be
              stored safely. We are not liable for the damage or loss of any
              data, for the storage of which we have employed third
              parties.</SPAN
            ></P
          >
          <P class="p46 ft26"
            ><SPAN class="ft8">11.5.</SPAN
            ><SPAN class="ft20"
              >The limitations set out in this article do not apply if damage is
              the result of an intentional act or gross negligence from our
              side.</SPAN
            ></P
          >
          <P class="p47 ft35"
            ><SPAN class="ft8">12.</SPAN
            ><SPAN class="ft34">Applicable law </SPAN></P
          >
          <P class="p18 ft12">Dutch Law.</P>
          <P class="p6 ft10"
            ><SPAN class="ft8">13.</SPAN
            ><SPAN class="ft9">Competent court</SPAN></P
          >
          <P class="p18 ft12">The court of Amsterdam.</P>
        </DIV>
      </v-col>
    </v-row>
  </v-container>
</template>
<!-- eslint-enable -->

<script>
import { mdiMovieOpen } from "@mdi/js";

export default {
  name: "Terms",
  data() {
    return {
      svgMovieOpen: mdiMovieOpen
    };
  }
};
</script>

<style scoped>
#page_1 {
  position: relative;
  overflow: hidden;
  padding-bottom: 64px;
  border: none;
  max-width: 699px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.ft0 {
  font-size: 27px;
  line-height: 32px;
}
.ft1 {
  font-style: italic;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
}
.ft2 {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
}
.ft3 {
  font-size: 14px;
  line-height: 24px;
}
.ft4 {
  font-size: 14px;
  line-height: 22px;
}
.ft5 {
  font-style: italic;
  font-size: 14px;
  line-height: 23px;
}
.ft6 {
  font-size: 14px;
  line-height: 23px;
}
.ft7 {
  font-size: 14px;
  line-height: 24px;
}
.ft8 {
  font-size: 14px;
  line-height: 24px;
}
.ft9 {
  font-size: 14px;
  font-weight: bold;
  margin-left: 3px;
  line-height: 16px;
}
.ft10 {
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
}
.ft11 {
  font-size: 14px;
  margin-left: 2px;
  line-height: 16px;
}
.ft12 {
  font-size: 14px;
  line-height: 16px;
}
.ft13 {
  font-size: 14px;
  margin-left: 3px;
  line-height: 23px;
}
.ft14 {
  font-size: 14px;
  line-height: 23px;
}
.ft15 {
  font-size: 14px;
  margin-left: 3px;
  line-height: 24px;
}
.ft16 {
  font-size: 14px;
  line-height: 14px;
}
.ft17 {
  font-size: 14px;
  margin-left: 4px;
  line-height: 23px;
}
.ft18 {
  font-size: 14px;
  margin-left: 4px;
  line-height: 22px;
}
.ft19 {
  font-size: 14px;
  line-height: 22px;
}
.ft20 {
  font-size: 14px;
  margin-left: 3px;
  line-height: 16px;
}
.ft21 {
  font-size: 14px;
  line-height: 15px;
}
.ft22 {
  font-size: 14px;
  margin-left: 3px;
  line-height: 24px;
}
.ft24 {
  font-size: 14px;
  margin-left: 3px;
  line-height: 22px;
}
.ft25 {
  font-size: 14px;
  margin-left: 3px;
  line-height: 23px;
}
.ft26 {
  font-size: 14px;
  line-height: 23px;
}
.ft27 {
  font-size: 14px;
  margin-left: 4px;
  line-height: 24px;
}
.ft28 {
  font-style: italic;
  font-size: 14px;
  line-height: 24px;
}
.ft29 {
  font-size: 14px;
  font-weight: bold;
  margin-left: 2px;
  line-height: 16px;
}
.ft30 {
  font-size: 14px;
  margin-left: 3px;
  line-height: 22px;
}
.ft31 {
  font-size: 14px;
  line-height: 24px;
}
.ft32 {
  font-size: 14px;
  margin-left: 2px;
  line-height: 22px;
}
.ft33 {
  font-size: 14px;
  margin-left: 5px;
  line-height: 23px;
}
.ft34 {
  font-size: 14px;
  font-weight: bold;
  margin-left: 4px;
  line-height: 23px;
}
.ft35 {
  font-size: 14px;
  font-weight: bold;
  line-height: 23px;
}

.p0 {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 0px;
}
.p1 {
  text-align: left;
  padding-left: 228px;
  margin-top: 16px;
  margin-bottom: 0px;
}
.p2 {
  text-align: left;
  margin-top: 29px;
  margin-bottom: 0px;
}
.p3 {
  text-align: left;
  margin-top: 25px;
  margin-bottom: 0px;
}
.p4 {
  text-align: left;
  margin-top: 22px;
  margin-bottom: 0px;
}
.p5 {
  text-align: left;
  margin-top: 21px;
  margin-bottom: 0px;
}
.p6 {
  text-align: left;
  padding-left: 1px;
  margin-top: 20px;
  margin-bottom: 0px;
}
.p7 {
  text-align: left;
  padding-left: 28px;
  margin-top: 9px;
  margin-bottom: 0px;
}
.p8 {
  text-align: left;
  padding-left: 28px;
  margin-top: 2px;
  margin-bottom: 0px;
}
.p9 {
  text-align: left;
  padding-left: 60px;
  margin-top: 2px;
  margin-bottom: 0px;
  text-indent: -32px;
}
.p10 {
  text-align: left;
  padding-left: 1px;
  margin-top: 24px;
  margin-bottom: 0px;
}
.p11 {
  text-align: left;
  padding-left: 60px;
  margin-top: 9px;
  margin-bottom: 0px;
  text-indent: -33px;
}
.p12 {
  text-align: left;
  padding-left: 60px;
  margin-top: 2px;
  margin-bottom: 0px;
  text-indent: -34px;
}
.p13 {
  text-align: left;
  padding-left: 1px;
  margin-top: 20px;
  margin-bottom: 0px;
}
.p14 {
  text-align: left;
  padding-left: 27px;
  margin-top: 10px;
  margin-bottom: 0px;
}
.p15 {
  text-align: left;
  padding-left: 60px;
  margin-top: 2px;
  margin-bottom: 0px;
  text-indent: -33px;
}
.p16 {
  text-align: left;
  padding-left: 60px;
  margin-top: 2px;
  margin-bottom: 0px;
  text-indent: -33px;
}
.p17 {
  text-align: left;
  margin-top: 23px;
  margin-bottom: 0px;
}
.p18 {
  text-align: left;
  padding-left: 27px;
  margin-top: 2px;
  margin-bottom: 0px;
}
.p19 {
  text-align: left;
  padding-left: 60px;
  margin-top: 2px;
  margin-bottom: 0px;
  text-indent: -33px;
}
.p20 {
  text-align: left;
  padding-left: 60px;
  margin-top: 2px;
  margin-bottom: 0px;
  text-indent: -33px;
}
.p21 {
  text-align: left;
  padding-left: 60px;
  margin-top: 8px;
  margin-bottom: 0px;
}
.p22 {
  text-align: left;
  padding-left: 60px;
  margin-top: 6px;
  margin-bottom: 0px;
}
.p23 {
  text-align: left;
  padding-left: 87px;
  margin-top: 7px;
  margin-bottom: 0px;
}
.p24 {
  text-align: left;
  padding-left: 1px;
  margin-top: 26px;
  margin-bottom: 0px;
}
.p25 {
  text-align: left;
  padding-left: 28px;
  margin-top: 10px;
  margin-bottom: 0px;
}
.p26 {
  text-align: left;
  padding-left: 28px;
  margin-top: 2px;
  margin-bottom: 0px;
}
.p27 {
  text-align: left;
  padding-left: 28px;
  margin-top: 2px;
  margin-bottom: 0px;
}
.p28 {
  text-align: left;
  padding-left: 59px;
  margin-top: 2px;
  margin-bottom: 0px;
  text-indent: -32px;
}
.p29 {
  text-align: left;
  padding-left: 59px;
  margin-top: 2px;
  margin-bottom: 0px;
  text-indent: -32px;
}
.p30 {
  text-align: left;
  margin-top: 24px;
  margin-bottom: 0px;
}
.p31 {
  text-align: left;
  padding-left: 27px;
  margin-top: 10px;
  margin-bottom: 0px;
  text-indent: 19px;
}
.p32 {
  text-align: left;
  padding-left: 185px;
  margin-top: 3px;
  margin-bottom: 0px;
}
.p33 {
  text-align: left;
  padding-left: 59px;
  margin-top: 7px;
  margin-bottom: 0px;
  text-indent: -33px;
}
.p34 {
  text-align: left;
  padding-left: 59px;
  margin-top: 9px;
  margin-bottom: 0px;
  text-indent: -33px;
}
.p35 {
  text-align: left;
  padding-left: 59px;
  margin-top: 3px;
  margin-bottom: 0px;
  text-indent: -33px;
}
.p36 {
  text-align: left;
  padding-left: 26px;
  margin-top: 6px;
  margin-bottom: 0px;
}
.p37 {
  text-align: left;
  padding-left: 26px;
  margin-top: 6px;
  margin-bottom: 0px;
}
.p38 {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 0px;
}
.p39 {
  text-align: left;
  padding-left: 46px;
  margin-top: 10px;
  margin-bottom: 0px;
  text-indent: -14px;
}
.p40 {
  text-align: left;
  padding-left: 113px;
  margin-top: 5px;
  margin-bottom: 0px;
}
.p41 {
  text-align: left;
  padding-left: 60px;
  margin-top: 7px;
  margin-bottom: 0px;
  text-indent: -33px;
}
.p42 {
  text-align: left;
  padding-left: 27px;
  margin-top: 6px;
  margin-bottom: 0px;
}
.p43 {
  text-align: left;
  padding-left: 60px;
  margin-top: 9px;
  margin-bottom: 0px;
  text-indent: -32px;
}
.p44 {
  text-align: left;
  padding-left: 28px;
  margin-top: 2px;
  margin-bottom: 0px;
}
.p45 {
  text-align: left;
  padding-left: 28px;
  margin-top: 2px;
  margin-bottom: 0px;
}
.p46 {
  text-align: left;
  padding-left: 60px;
  margin-top: 2px;
  margin-bottom: 0px;
  text-indent: -32px;
}
.p47 {
  text-align: left;
  padding-left: 27px;
  margin-top: 26px;
  margin-bottom: 0px;
  text-indent: -26px;
}
</style>
